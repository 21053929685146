/*
 * @Description: 
 * @Author: 
 * @Date: 2023-03-15 15:30:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-25 13:57:22
 */
// 权限管理的网络请求

import {request} from "./request";

// 获取权限列表
export function getRightsListRequest(params) {
  return request({
    url: `/platform/menu/list`,
    params,
  })
}

// 获取角色列表
export function getRolesListRequest(params) {
  return request({
    url: '/platform/role/page',
    params
  })
}


// 添加角色
export function addRoleRequest(roleInfo) {
  return request({
    url: '/platform/role/add',
    method: 'post',
    params: {...roleInfo}
  })
}

// 根据角色id获取角色信息
export function getRoleByIdRequest(id) {
  return request({
    url: 'platform/role/one',
    params: id,
  })
}

// 编辑角色
export function editRoleRequest(roleInfo) {
  return request({
    url: 'platform/role/update/power',
    method: 'post',
    params: {...roleInfo}
  })
}

// 删除角色
export function removeRoleByIdRequest(params) {
  return request({
    url: '/platform/role/update/role',
    method: 'post',
    params: {...params}
  })
}

// 为角色添加权限
export function addRightsRequest(roleId, rids) {
  return request({
    url: `roles/${roleId}/rights`,
    method: 'post',
    data: {
      rids
    }
  })
}

// 根据ID删除指定角色下的权限
export function removeRightByIdRequest(roleId, rightId) {
  return request({
    url: `roles/${roleId}/rights/${rightId}`,
    method: 'delete'
  })
}

// 查询主题
export function getQueryStyleRequest(params){
  return request ({
    url: '/platform/style/queryStyle',
    params
  })
}

// 保存主题
export function getStyleRequest(params){
  return request ({
    url: 'platform/style/updateStyle',
    method: 'get',
    params
  })
}